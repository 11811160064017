import React from 'react';
import styled from 'styled-components';

import { ColourLevel } from '../types';
import SpeakersList, { SpeakersListProps } from './SpeakersList';
import TitleSeperator from './TitleSeperator';

const Day = styled.h2<{ $colour: string }>`
  && {
    color: ${({ $colour }) => $colour};
    margin-bottom: ${({ theme }) => theme.sizes.lvl1};
    text-align: center;
    text-transform: uppercase;
  }
`;

const Description = styled.h2<{ $colour: string }>`
  && {
    text-align: center;
    max-width: 480px;
    width: 100%;
    margin: 0 auto ${({ theme }) => theme.sizes.lvl4};
    color: ${({ $colour }) => $colour};
  }
`;

interface SpeakersListAndTitleProps extends SpeakersListProps {
  title?: string;
  titleColour?: string;
  description?: string;
  day?: string;
}

const SpeakersListAndTitle = ({
  className,
  speakers,
  title,
  description,
  day,
  titleColour = 'black',
  colourLevel = ColourLevel.SECONDARY,
  hideBuyButton,
  date,
}: SpeakersListAndTitleProps) => {
  return (
    <div className={className}>
      {day && <Day $colour={titleColour}>Day {day}</Day>}
      {description && (
        <Description $colour={titleColour} className="h4">
          {description}
        </Description>
      )}
      {title && <TitleSeperator text={title} colour={titleColour} />}
      <SpeakersList
        colourLevel={colourLevel}
        hideBuyButton={hideBuyButton}
        date={date}
        speakers={speakers}
      />
    </div>
  );
};

export default SpeakersListAndTitle;
