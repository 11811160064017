import theme from '../utils/theme';
import { ColourLevel } from '../types';

export const dayMeta = {
  '2022-03-21': {
    colourLevel: ColourLevel.TERTIARY,
  },
  '2022-03-22': {
    colourLevel: ColourLevel.TERTIARY,
  },
  '2022-03-23': {
    colourLevel: ColourLevel.TERTIARY,
  },
  '2022-03-24': {
    colourLevel: ColourLevel.TERTIARY,
  },
  '2022-03-25': {
    colourLevel: ColourLevel.TERTIARY,
  },
  '2022-03-26': {
    colourLevel: ColourLevel.TERTIARY,
  },
  '2022-03-27': {
    colourLevel: ColourLevel.TERTIARY,
  },
};
